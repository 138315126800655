<template>
  <div>
    <vs-card v-if="orders.length == 0">
      <div slot="header">
        <p>Não há pedidos</p>
      </div>
    </vs-card>

    <vs-card :key="index" v-for="(order, index) in orders">
      <div slot="header">
        <h3 :style="{ color: order.classification.color }">
          Pedido {{ order.code }} - [{{ order.classification.name }}]
        </h3>
      </div>
      <div class="card">
        <p>
          Solicitado em {{ order.created }} pelo usuário
          <strong>{{ order.createdUser.name }}</strong>
        </p>
        <p>
          Total de itens do pedido: <strong>{{ order.items.length }}</strong>
        </p>
        <p>
          Status: <strong>{{ order.phase.name }}</strong>
        </p>
        <vs-divider></vs-divider>
        <h4>Itens do pedido</h4>
        <ul>
          <li :key="index" v-for="(item, index) in order.items">
            {{ item.project.name }} - [{{ item.qtd }} unidade(s)]
          </li>
        </ul>
      </div>
      <div slot="footer">
        <vs-button @click="cancel(order)" v-if="order.phase.id <= 3"
          >Cancelar</vs-button
        >
      </div>
    </vs-card>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import {
  notifyError,
  notifySuccess,
  formatDateToLocale,
} from "@/utils/helpers";
export default {
  data() {
    return {
      orders: [],
    };
  },

  created() {
    this.loadOrders();
  },

  methods: {
    cancel(order) {
      const data = { phaseId: 10 }; /*cancelar */
      this.$vs.loading();

      new siriusAPI()
        .updatePhase(order.uuid, data)
        .then(() => {
          notifySuccess(this, "Pedido cancelado com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar cancelar o pedido: " + error);
        });
    },

    loadOrders() {
      this.$vs.loading();
      new siriusAPI()
        .getOrders(false, false, false, true, "", "")
        .then((res) => {
          this.orders = res.data;
          this.orders = this.orders.map((order) => ({
            ...order,
            created: formatDateToLocale(order.createdAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de pedidos " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
}
</style>
